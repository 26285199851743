@import "../../../../assets/stylesheet/global/varibale";
@import "./../../../../assets/stylesheet/global/mixin";

.navbar {
  align-items: center;
  display: inline-flex;
  margin-right: 15px;//35px
  margin-bottom: 0;
  margin-left: 15px; //40px

  @include break-point(tablet-view) {
    display: flex;
    margin-right: 0;
    margin-left: 0;
  }
 
  .option {
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 16px;
    padding: 22px 13px;
    position: relative;
    &:hover{
      .user-dropdown{
        display: block !important;
      }
    }
    .user-dropdown{
      background-color: #fff;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 3px 10px 0 rgba(161, 168, 188, .5);
      cursor: default;
      display: none;
      font-family: VoltePlay_Medium, sans-serif;
      font-size: 16px;
      position: absolute;
      top: 68px;
      left: 0;
      width: 200px;
      z-index: 99;

      .option-menu{
        align-items: center; 
        cursor: pointer;
        padding: 10px 15px;
        z-index: 999;
        border-bottom: 1px solid #efefef;
        &:hover{
          background: #fbd0e2;
        }
        a::after{
          display: none;
        }
      }
        }

    .disabled-link {
      opacity: .3;
      pointer-events: none;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      color: $font-default-color;
      text-align: center;
      &.active {
        color: $font-default-color;

        &:after {
          border-bottom: 4px solid #E10092;
          border-radius: 2px;
          content: '';
          height: 4px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }

      &:hover {
        color: $font-default-color;
      }
    }

    @include break-point(tablet-view) {
      font-family: VoltePlay_Regular, sans-serif;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 5px 10px;
    }

    @include break-point(ie) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

